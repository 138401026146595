import React from 'react'
import Layout from '../components/layout'
import SearchInput from '../components/SearchInput'
import Seo from "../components/seo"

const OtherEPCCountries = () => {
    return (
        <Layout>
            <Seo title="Other EPC Countries" />

            <h1 className="title">Other EPC Countries</h1>

            <SearchInput />

            <table>
                <tbody>
                    <tr>
                        <td><u>Germany</u> German Patent and Trademark Office search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.dpma.de/english/">https://www.dpma.de/english/</a></td>
                    </tr>

                    <tr>
                        <td><u>France</u> INPI search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://data.inpi.fr/recherche_avancee/brevets">https://data.inpi.fr/recherche_avancee/brevets</a></td>
                    </tr>

                    <tr>
                        <td><u>Italy</u> Italian Patent and Trademark Office search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://uibm.mise.gov.it/index.php/en/">https://uibm.mise.gov.it/index.php/en/</a></td>
                    </tr>

                    <tr>
                        <td><u>Spain</u> OEPM</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.oepm.es/en/index.html">https://www.oepm.es/en/index.html</a></td>
                    </tr>

                    <tr>
                        <td><u>Austria</u> Austrian patent office</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.patentamt.at/en/">https://www.patentamt.at/en/</a></td>
                    </tr>

                    <tr>
                        <td><u>Norway</u> Patentstyret (search trademarks, patents and designs)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://search.patentstyret.no/advanced/">https://search.patentstyret.no/advanced/</a></td>
                    </tr>

                    <tr>
                        <td><u>Finland</u> Patent and Registration Office patent, utility model and SPC search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://patent.prh.fi/patinfo/default2.asp">https://patent.prh.fi/patinfo/default2.asp</a></td>
                    </tr>

                    <tr>
                        <td><u>Iceland</u> Hugverkastofan patent search engine</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.hugverk.is/einkaleyfaleitarvel">https://www.hugverk.is/einkaleyfaleitarvel</a></td>
                    </tr>

                    <tr>
                        <td><u>Ireland</u> Intellectual Property Office of Ireland SPC database</td>
                        <td><a target="_blank" rel="noreferrer" href="https://eregister.patentsoffice.ie/query/SPQuery.aspx">https://eregister.patentsoffice.ie/query/SPQuery.aspx</a></td>
                    </tr>
                </tbody>
            </table>
        </Layout>
    )
}

export default OtherEPCCountries